<template>
  <body id="app">
    <header>
      <nav
        class="navbar navbar-expand-sm navbar-toggleable-sm navbar-light bg-white border-bottom box-shadow mb-3"
      >
        <div class="container">
          <div class="text-center">
            <a class="navbar-brand">
              <img src="./assets/logo.png" />
            </a>
          </div>
        </div>
      </nav>
    </header>
    <div class="container">
      <main role="main" class="pb-3">
        <div class="row">
          <div class="col-md-6">
            <h2>Software ontwikkeling/architectuur</h2>
            <ul>
              <li>Microsoft.NET (Core)</li>
              <li>ASP.NET (MVC, WebApi, Core)</li>
              <li>Azure</li>
              <li>SQL Server</li>
              <li>Visual Studio</li>
              <li>RabbitMQ</li>
              <li>Hangfire</li>
              <li>Docker</li>
              <li>XAML/WPF</li>
              <li>Xamarin iOS / Android</li>
              <li>Office 365 integration (Exchange, Sharepoint)</li>
              <li>Akka.NET</li>
              <li>Delphi</li>
            </ul>
          </div>
          <div class="col-md-6">
            <h2>Contactgegevens</h2>
            <p>
              Telefoon : nul zes 19389470<br />
              Email : info AT davan.nl<br />
              KvK : 58552626<br />
              <a href="http://www.linkedin.com/in/daninden">Linkedin</a>
            </p>
          </div>
        </div>
      </main>
    </div>

    <footer class="border-top footer text-muted">
      <div class="container">&copy; Davan</div>
    </footer>
  </body>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

